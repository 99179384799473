import { lazy } from 'react';
import { AnimatedPageLoader, PadContainer } from 'smartsuite-ui';
import airlineCodes from './customer/airlineCodes';
import { useSettings } from './utils/apiUtils';

const AeromexicoWrapper = lazy(() =>
    import('./customer/Aeromexico/AeromexicoWrapper')
);
const AirCanadaWrapper = lazy(() =>
    import('./customer/AirCanada/AirCanadaWrapper')
);
const LatamWrapper = lazy(() => import('./customer/Latam/LatamWrapper'));
const SmartSuiteWrapper = lazy(() =>
    import('./customer/SmartSuite/SmartSuiteWrapper')
);
const VirginAustraliaWrapper = lazy(() =>
    import('./customer/VirginAustralia/VirginAustraliaWrapper')
);

function AppRouter() {
    const { isFetching, isError, carrierCode } = useSettings();

    if (isFetching) {
        return (
            <PadContainer>
                <AnimatedPageLoader />
            </PadContainer>
        );
    }

    if (isError) {
        return <div>Unable to retrieve settings</div>;
    }

    if (carrierCode === airlineCodes.aeromexico) {
        return <AeromexicoWrapper />;
    } else if (carrierCode === airlineCodes.airCanada) {
        return <AirCanadaWrapper />;
    } else if (carrierCode === airlineCodes.latam) {
        return <LatamWrapper />;
    } else if (carrierCode === airlineCodes.virginAustralia) {
        return <VirginAustraliaWrapper />;
    } else {
        return <SmartSuiteWrapper />;
    }
}

export default AppRouter;
